import { useSecurePost } from '~/composables/dataFetching/genericFetchers';
import type { Result } from '~/server/api/[site]/user/resetPw.post';
import { handleLoadingError } from '~/utils/handleLoadingError';

export function useSendForgotPasswordForm() {
  const site = useSiteIdent();
  const isLoading = ref(false);
  const hasError = ref(false);
  const showSuccessView = ref(false);
  const email = ref<string>();

  interface resetPwForm {
    email: string;
  }

  async function onSubmit(data: resetPwForm) {
    isLoading.value = true;
    email.value = data.email;
    await sendEmail(data);
    isLoading.value = false;
  }

  async function sendEmail(formData: resetPwForm) {
    try {
      isLoading.value = true;
      const postData = formData;

      const result = await useSecurePost<Result>(
        `/api/${site}/user/resetPw`,
        postData,
      );

      if (result) {
        showSuccessView.value = true;
      }

      isLoading.value = false;
    } catch (e) {
      hasError.value = true;
      document.querySelector('.js-error-container')?.scrollIntoView({
        behavior: 'smooth',
      });
      isLoading.value = false;
      handleLoadingError(e);
    }
  }

  return {
    isLoading,
    hasError,
    showSuccessView,
    onSubmit,
    email,
  };
}
